import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Header from "../components/header"

const IndexPage = (location) => {

  const secret = "Conference2021";

  const [p, setClass] = useState();

  useEffect(() => {
    let param = new URLSearchParams(window.location.search).get('p');
    setClass(param);

    const button = document.querySelector('.enter');
    const input = document.querySelector('input')
    button.addEventListener('click', () => {
      if (input.value === secret || param === secret) {
        navigate(`/programme?p=${secret}`);
      } else {
        button.classList.add('error')
        setTimeout(() => {
          input.value = "";
          button.classList.remove('error')
        }, 2500)
      }
    })
  }, [])

  return (
    <div>
      <div className="content">
        <Header />
        <h1>Education and Illustration: Models, Methods, Paradigms</h1>
        <div className="inner">
          <div className="home">
            <div>
              <h2>11th Illustration Research Symposium</h2>
              <p>11-12 February 2021</p>
              <p>Kingston University, London UK</p>
            </div>
            <img src="/ksa.jpg" alt="Kingston School of Art" className="logo" />
          </div>

          <div className="login">
            <label>
              <input id="password" type="text" placeholder="Password" className={p === secret ? "hide" : ""}/>
            </label>
            <button className="enter">Access Recordings</button>
          </div>
        </div>
      </div>
    </div>
  )
}


export default IndexPage
